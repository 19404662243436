/* Reset and Base Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f9fa;
  color: #0f1419;
}

/* Layout Container */
.container {
  max-width: 598px;
  margin: 0 auto;
  padding: 16px;
}

/* Dashboard Header */
.dashboard-header {
  padding: 16px 0;
  border-bottom: 1px solid #e1e8ed;
  margin-bottom: 16px;
}

.dashboard-title {
  font-size: 20px;
  font-weight: 800;
  color: #0f1419;
}

/* Add Tweet Form */
.tweet-form {
  background-color: #ffffff;
  border: 1px solid #e1e8ed;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
}

.tweet-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #cfd9de;
  border-radius: 4px;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 12px;
  resize: none;
}

.tweet-input:focus {
  outline: none;
  border-color: #1d9bf0;
  box-shadow: 0 0 0 1px #1d9bf0;
}

/* Tweet Card Specific Styles */
.tweet-card {
  max-width: 598px;
  width: 100%;
  transition: background-color 0.2s;
}

.tweet-card-inner {
  padding: 12px 16px;
  border: 1px solid #e1e8ed;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 12px;
}

.tweet-card:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #e1e8ed;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  color: #536471;
}

.tweet-header {
  display: flex;
  align-items: baseline;
  gap: 4px;
  font-size: 15px;
}

.tweet-name {
  font-weight: 700;
  color: #0f1419;
}

.tweet-handle,
.tweet-status {
  color: #536471;
}

.tweet-content {
  margin-top: 8px;
  font-size: 15px;
  line-height: 1.3125;
  overflow-wrap: break-word;
}

/* Action Buttons */
.action-buttons {
  display: flex;
  gap: 8px;
  margin-top: 12px;
}

.btn {
  flex: 1;
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-approve {
  background-color: #b8bdc8;
  color: #ffffff;
}

.btn-approve:hover {
  background-color: #22c55e;
}

.btn-reject {
  background-color: #b8bdc8;
  color: #ffffff;
}

.btn-reject:hover {
  background-color: #4b5563;
}

.btn-delete {
  background-color: #b8bdc8;
  color: #ffffff;
}

.btn-delete:hover {
  background-color: #dc2626;
}

/* Utility Classes */
.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.gap-3 {
  gap: 12px;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.items-baseline {
  align-items: baseline;
}

.break-words {
  overflow-wrap: break-word;
}

.compose-tweet-form {
  width: 100%;
}

.compose-tweet-input {
  width: 100%;
  border: none;
  font-size: 20px;
  outline: none;
  padding: 12px 0;
  resize: none;
  margin-bottom: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.compose-tweet-input::placeholder {
  color: #536471;
}

.compose-tweet-actions {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eee;
  padding-top: 12px;
}

.compose-tweet-button {
  background-color: rgb(29, 155, 240);
  color: white;
  border: none;
  border-radius: 9999px;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.compose-tweet-button:hover {
  background-color: rgb(26, 140, 216);
}

.tweets-container {
  margin-top: 16px;
}

/* Add to index.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7f9fa;
  font-family: Menlo, monospace;
}

.login-card {
  width: 100%;
  max-width: 320px;
  background-color: white;
  border: 1px solid #e1e8ed;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 32px;
  font-family: Menlo, monospace;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
}

.login-input {
  width: 80%;
  height: 40px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #e1e8ed;
  background-color: white;
  font-family: Menlo, monospace;
  text-align: center;
}

.login-input:focus {
  outline: none;
  border-color: #000;
}

.login-input::placeholder {
  color: #536471;
  text-align: center;
}

.login-button {
  width: 80%;
  height: 40px;
  margin-top: 8px;
  background-color: #000;
  color: white;
  border: none;
  font-size: 14px;
  font-family: Menlo, monospace;
  cursor: pointer;
  transition: background-color 0.2s;
}

.login-button:hover {
  background-color: #333;
}

.login-error {
  background-color: #fce8e8;
  border: 1px solid #fbd5d5;
  color: #c81e1e;
  padding: 12px;
  margin-bottom: 24px;
  font-size: 14px;
  text-align: center;
  width: 80%;
}